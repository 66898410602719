import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('insights'),
    name: Route.Insights,
    component: () => import('@/modules/insights/views/Insights.vue'),
  },
]
