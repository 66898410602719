import { z } from 'zod'

export const subtaskSchema = z.strictObject({
  id: z.number(),
  finished: z.boolean(),
  position: z.number(),
  title: z.string(),
  userId: z.number().nullable(),
  dueDate: z.coerce.date().nullable(),
})
export type Subtask = z.infer<typeof subtaskSchema>
