import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Feature } from '../ability/composable/useFeatures'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('reporting'),
    name: Route.Reporting,
    redirect: { name: Route.MaterialityImpact },
    meta: {
      requireFeatures: [Feature.Reporting],
    },
    children: [
      {
        path: 'materiality',
        name: Route.Materiality,
        redirect: { name: Route.MaterialityImpact },
        component: () => import('@/modules/reporting/views/Materiality.vue'),
        meta: {
          requireFeatures: [Feature.ReportingMateriality],
        },
        children: [
          {
            path: 'impact',
            name: Route.MaterialityImpact,
            component: () =>
              import('@/modules/reporting/views/MaterialityImpact.vue'),
          },
          {
            path: 'financial',
            name: Route.MaterialityFinancial,
            component: () =>
              import('@/modules/reporting/views/MaterialityFinancial.vue'),
          },
          {
            path: 'assessment-criteria',
            name: Route.MaterialityAssessmentCriteria,
            component: () =>
              import(
                '@/modules/reporting/views/MaterialityAssessmentCriteria.vue'
              ),
          },
        ],
      },
    ],
  },
]
