import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('my-tasks'),
    name: Route.MyTasks,
    component: () => import('@/modules/myTasks/views/MyTasks.vue'),
  },
]
