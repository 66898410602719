import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Feature } from '../ability/composable/useFeatures'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('settings'),
    name: Route.SettingsDefault,
    component: () => import('@/modules/settings/views/Settings.vue'),
    redirect: { name: Route.SettingsUsers },
    children: [
      {
        path: 'organization',
        // Keep for backwards compatibility
        alias: 'general-info',
        name: Route.SettingsOrganization,
        component: () =>
          import('@/modules/settings/views/SettingsOrganization.vue'),
        meta: {
          requireFeatures: [Feature.SettingsOrganization],
        },
      },
      {
        path: 'locations',
        name: Route.SettingsLocations,
        component: () =>
          import('@/modules/settings/views/SettingsLocations.vue'),
        meta: {
          requireFeatures: [Feature.SettingsLocations],
        },
      },
      {
        path: 'users',
        name: Route.SettingsUsers,
        component: () => import('@/modules/settings/views/SettingsUsers.vue'),
        meta: {
          requireFeatures: [Feature.SettingsUsers],
        },
      },
      {
        path: 'my-account',
        name: Route.SettingsMyAccount,
        component: () =>
          import('@/modules/settings/views/SettingsMyAccount.vue'),
      },
    ],
  },
]
