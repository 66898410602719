import { z } from 'zod'
import { subtaskSchema } from './useSubtask.types'
import { tagSchema } from './useTag.types'

export enum ActionState {
  Pipeline = 'pipeline',
  InProgress = 'in_progress',
  Done = 'done',
  Paused = 'paused',
}

export enum ActionCategory {
  CompanyVehicles = 'company_vehicles',
  Employees = 'employees',
  Management = 'management',
  OfficeBuilding = 'office_building',
  OwnElectricity = 'own_electricity',
  Product = 'product',
}

export enum ActionComplexity {
  None = 'none',
  Easy = 'easy',
  Doable = 'doable',
  Complex = 'complex',
}

export enum ActionImpact {
  None = 'none',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum ActionScope {
  First = 1,
  Second = 2,
  Third = 3,
}

export enum ActionTimeEffort {
  None = 'none',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum ActionConfidence {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum ActionEmissionsSavedEstimationAvailability {
  EffectivenessMissing = 'effectiveness_missing',
  AssessmentMissing = 'assessment_missing',
  EstimationAvailable = 'estimation_available',
}

export enum ActionEmissionsSavedValueSource {
  Estimation = 'estimation',
  EmissionCalculator = 'emission_calculator',
}

export const actionBudgetingSchema = z.strictObject({
  capitalExpenditure: z.number().nullable(),
  emissionsSaved: z.number().nullable(),
  emissionsSavedEstimationAvailability: z.nativeEnum(
    ActionEmissionsSavedEstimationAvailability
  ),
  emissionsSavedValueSource: z
    .nativeEnum(ActionEmissionsSavedValueSource)
    .nullable(),
  energySaved: z.number().nullable(),
  expectedUsefulLife: z.number().nullable(),
  internalRateOfReturn: z.number().nullable(),
  moneySaved: z.number().nullable(),
  netPresentValue: z.number().nullable(),
  operationalExpenditure: z.number().nullable(),
  paybackPeriod: z.number().nullable(),
  returnOnInvestment: z.number().nullable(),
  useValeriCalculator: z.boolean(),
})
export type ActionBudgeting = z.infer<typeof actionBudgetingSchema>

export const actionDataSchema = z.strictObject({
  active: z.boolean(),
  budgeting: actionBudgetingSchema,
  category: z.nativeEnum(ActionCategory),
  companyId: z.number(),
  complexity: z.nativeEnum(ActionComplexity),
  confidence: z.nativeEnum(ActionConfidence).nullable(),
  dueDate: z.coerce.date().nullable(),
  description: z.string().nullable(),
  finishedAt: z.coerce.date().nullable(),
  id: z.number(),
  impact: z.nativeEnum(ActionImpact),
  locationId: z.number().nullable(),
  progress: z.number().nullable(),
  scope: z.nativeEnum(ActionScope),
  startedAt: z.coerce.date().nullable(),
  state: z.nativeEnum(ActionState),
  subscope: z.number().nullable(),
  subtasks: z.array(subtaskSchema).optional(),
  subtasksCountAll: z.number().nullable(),
  subtasksCountFinished: z.number().nullable(),
  tags: z.array(tagSchema),
  targetOrder: z.number(),
  targetQuarter: z.number(),
  targetYear: z.number(),
  templatePosition: z.number().nullable(),
  timeEffort: z.nativeEnum(ActionTimeEffort),
  title: z.string(),
  userId: z.number().nullable(),
})
export type ActionData = z.infer<typeof actionDataSchema>

export type RoadmapPosition = Pick<ActionData, 'targetYear' | 'targetQuarter'>
